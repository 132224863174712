<template>
    <div  class="msp-confirm" :class="classes" >
    <div @click.stop="open" :class="classesButtons" class="msp-confirm__button">
    <slot></slot>
    </div>
    <div class="msp-confirm__options">
    <p v-if="textConfirm">{{textConfirm}}</p>
    <button type="button" class="btn msp-confirm__abort btn-grigio"  @click.stop="abort()" v-html="myButtonAbort"></button>
    <button type="button" class="btn msp-confirm__confirm btn-danger" @click.stop="confirm()" v-html="myButtonConfirm"></button>
    </div>
    </div>
</template>
<script>
const component = {
    props: [ 
        'align',
        'button-abort',
        'button-confirm',
        'class-button',
        'justify',
        'text-confirm',
        'text',
    ],
    computed: {
        classesButtons: function () {
            let classes = [];
            if (this.classButton) classes.push(this.classButton);
            // if (!this.text) { classes.push("btn btn-danger btn-icon"); }

            return classes.join(" ");

        },
        classes: function () {
            let classes = [];
            if (this.justify) classes.push(this.justify);
            if (this.align) classes.push(this.align);
            if (this.isOpen) classes.push("active");

            return classes.join(" ");

        }
    },
    data: function () {
        return {
            isOpen: false,
            myButtonConfirm: this.buttonConfirm || 'conferma',
            myButtonAbort: this.buttonAbort || 'annulla',
        }
    },
    watch: {

    },
    mounted: function () {

    },
    methods: {
        stopPropagation: function ()
        {
            return false;
        },
        open: function ()
        {
            this.isOpen = true;
        },
        abort: function () {
            this.isOpen = false;
            this.$emit('abort');
        },
        confirm: function () {
            this.isOpen = false;
            this.$emit('confirm');
        }

    }
};
export default component;
</script>
<style lang="scss">
div.msp-confirm {
    position: relative;
    width: auto;
    .msp-confirm__button {
        width: auto;
    }
    .msp-confirm__options {
        display: none !important;
    }
    &.active {
        .msp-confirm__button {
            opacity: 0 !important;
            pointer-events: none !important;
        }
        .msp-confirm__options {
            display: flex !important;
            gap: 0 0.5em;
        }

    }

    &.right {
        .msp-confirm__options {
            left: auto;
            right: 0;
        }
    }

    .msp-confirm__options {
        position: absolute;
        top: 50%;
        left:0;
        z-index: 1;
        transform: translateY(-50%);
        background-color: #fff;
        padding: var(--ui-space);
        border-radius: var(--ui-radius);
        box-shadow: 0px 0px 5px rgba(0,0,0,0.6);
    }

}
</style>
