<template>
    <div class="zone">
        <header v-if="titolo"  class="msp-ui-panel-header">
            <h3 v-text="titolo"></h3>
        </header>
        <table v-if="!isNuoto(sport_id)" class="msp-ui-table  table-striped table-nospan table-zone table-zone-z">
            <thead>
                <tr>
                    <th>Zona</th>
                    <th>Da</th>
                    <th>A</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="zona in filteredZone" :key="zona.id">
                    <td class="table-zone-label" :style="setStyle(coloreZona(zona.id),15)">{{nomeZona(zona.id)}}</td>
                    <td :style="setStyle(coloreZona(zona.id),15)"><edit-zona @update="onUpdateZona" :mode="modeZona" :zona="zona" key_valore="da" :um_id="um_id" :um_descrizione="um_descrizione"/></td>
                    <td :style="setStyle(coloreZona(zona.id),15)"><edit-zona @update="onUpdateZona"  :mode="modeZona" :zona="zona" key_valore="a" :um_id="um_id" :um_descrizione="um_descrizione"/></td>
                    <td :style="setStyle(coloreZona(zona.id),15)">{{um_descrizione}}</td></tr>
            </tbody>
        </table>

        <table v-if="isNuoto(sport_id)" class="msp-ui-table  table-striped table-nospan table-zone">
        <thead>
        <tr>
        <th>Zona</th>
        <th v-for="distanza in distanze"
        :key = "distanza.id"
        >{{distanza.name}}</th>

        </tr>
        </thead>
            <tbody>
                <tr v-for="zona in filteredZone" :key="zona.id+'-'+zona.distanza">
                    <td class="table-zone-label" :style="setStyle(coloreZona(zona.id),15)">{{zona.nome}}</td>
                    <td v-for="distanza in distanze" :key = "distanza.id"
                    :style="setStyle(coloreZona(zona.id),15)">
                    <edit-zona @update="onUpdateZona" :mode="mode" :zona="zonaByDistanza(zona,distanza.id)" key_valore="media" :um_id="um_id" :um_descrizione="um_descrizione"></edit-zona>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="msp-ui-alert" v-if="!hasZones">
            <div class="alert-nozone" >
                <p>zone</p>
            </div>
        </div>
        <div class="buttons" v-if="mode==='editable'">
            <template v-if="!isEditing">
                <button class="btn" @click="startEditing">modifica</button>
            </template>
            <template v-else>
                <button class="btn" @click="abort" >Annulla</button>
                <button class="btn" @click="save" >Salva</button>
                <msp-confirm class="btn" @confirm="reset" >Reset</msp-confirm>

            </template>
        </div>
    </div>

</template>

<script>


import _funzioni from '@src/js/msp/funzioni.js'
import env from '@src/mobile/src/mixins/environment.js';
import EditZona from './EditZona.vue';
import _constants from '@src/js/msp/constants.js';
import distanze from "@lib/distanze.js";
import _zone from "@cached/zone.json";
import _ums from "@cached/um.json";
import _umTipi from "@cached/um_tipo.json";
import MspConfirm from "@src/js/vue/include/MspConfirm.vue";
const component = {
    components: {
        EditZona,
        MspConfirm,
    },
    mixins: [
        env,
    ],
    props: ['titolo', 'mode', 'value', 'sport_id', 'um_id', 'um_descrizione' ],
    data: function () {
        const data = {
            isEditing: false,
            distanze: distanze,
            ZONE:_zone,
            zone: [],
        };
        return data;
    },
    created: function () {


    },
    computed: {
            modeZona: function () {
                if (this.isEditing) {
                    return "edit";
                } else {
                    return "view";
                }
            },
        hasZones: function() {
            if (this.sport_id == _constants.ID_NUOTO) {
                return !!(this.zone && Object.getOwnPropertyNames(this.zone).length);
            } else {
                return this.zone && this.zone.length;
            }
        },

        zonaIds: function () {
            return this.ZONE
            .filter((el)=>(+el.sport_id == +this.sport_id))
            .map((el)=>+el.id)
            .sort((a,b)=>(+a>+b?1:-1));
        },

        filteredZone: function () {
            if (!(this.zone && this.zone.length)) {
                return [];
            }
            if (this.isNuoto(this.sport_id)) {
                let filtered = this.ZONE.filter((zona)=>(zona.id != _constants.ZONE.REC && this.zonaIds.indexOf(+zona.id)!==-1));
                return filtered;
            }
            let filtered = this.zone.filter((zona)=>(zona.id != _constants.ZONE.REC && this.zonaIds.indexOf(+zona.id)!==-1));
            return filtered;
        }

    },


    mounted: function () {
        this.initZone();
    },
    watch: {
        value: {
            deep: true,
            handler: function () {
                this.initZone();
            },
        },
    },
    methods: {
            startEditing: function () {
                this.isEditing = true;
            },

            initZone: function () {
                this.zone = this.value;
            },

            stopEditing: function () {
                this.isEditing = false;
            },

            abort: function () {
                this.initZone();
                this.stopEditing();
            },
            save: function () {
                const checkZone = this.checkZone(this.zone);
                if (checkZone.isValid) {
                    this.$emit('input', this.zone);
                    this.stopEditing();
                } else {
                    const errors = checkZone.errors.map(this.makeError);
                    this.showAlert(errors);
                }
            },

            showAlert: function (errors) {
                const stringErrors = errors.join('\n');
                alert(`Valori non validi:\n\n${stringErrors}`);
            },
            
            makeError: function ({zonaId}) {
                const zona = _zone.find(el=>el.id === zonaId);
                return `${zona.codice}`;
            },

            checkZone: function (zone) {
                const errors = zone.reduce((acc,zona)=>{
                        const isValid = this.zonaIsValid(zona);

                        if (!isValid) {
                            const error = {
                                zonaId: zona.id,
                            };
                            acc.push(error);
                        }
                    return acc;

                }, []);
                const isValid = !errors.length;

                return {
                    isValid: isValid,
                    errors: errors,
                }
            },

            reset: function () {
                this.$emit('reset');
                this.initZone();
                this.stopEditing();
            },


            zonaIsValid: function (zona) {
                if (!zona.a || !zona.da) {
                    return true;
                }

                const um = _ums.find(el=>el.id === this.um_id);
                const umTipo = _umTipi.find(el=>el.id === um.um_tipo_id);


                let isValid;

                if (umTipo.more_is_better) {
                    isValid = zona.a > zona.da;
                } else {
                    isValid = zona.a < zona.da;
                }

                return isValid;

            },

            onUpdateZona: function ({value, zona, key_valore}) {
                const newZone = [...this.zone];
                let zi = this.zone.findIndex(el=>el.id === zona.id);
                const incLinked = (key_valore === 'da') ? -1 : 1;
                const linkedIndex = zi + incLinked;
                const linkedKey = (incLinked === -1) ? "a" : "da";

                newZone[zi] = {
                    ...newZone[zi],
                    [key_valore]: value,
                };
                if ( (newZone[linkedIndex]) &&
                    (newZone[linkedIndex][linkedKey]) ) {
                    if (newZone[linkedIndex].id === _constants.ZONE.REC) {
                        // il recupero da fermo ha sempre valori 0
                        value = 0;
                    }

                    newZone[linkedIndex] = {
                        ...newZone[linkedIndex],
                        [linkedKey]: value,
                    };
                }
                this.zone = [...newZone];
            },

        zonaByDistanza: function (zona, distanzaId) {
            let filtered = this.zone.find((el)=>(+el.id == +zona.id && el.distanza.toString() == distanzaId.toString()));
            return filtered;
        },

        setBackground(col, a) {
            return _funzioni.setBackground(col, a);
        },
        setBorder(col) {
            return _funzioni.setBorder(col);
        },
        setTextWhite(col) {
            return _funzioni.setTextWhite(col);
        },

        coloreZona: function (id) {
            let zona = _zone.find(el=>el.id===id);
            return zona && zona.colore;
        },
        nomeZona: function (id) {
            let zona = _zone.find(el=>el.id===id);
            return zona && zona.nome;
        },
        setStyle(col, a, toWhite) {
            var style = "";
            style += _funzioni.setBackground(col, a);
            style += _funzioni.setBorder(col);
            if (toWhite) {
                style += _funzioni.setTextWhite(col);
            }
            return style;
        },

        isNuoto(sport_id) {
            return parseInt(sport_id, 10) === _constants.ID_NUOTO;
        },

        formatRisultato(val, um) {
            return _funzioni.formatRisultato(val, um);

        },
        formatRisultatoByUmId(val, um_id, um_descrizione, hasUm = true) {
            return _funzioni.formatRisultatoByUmId(val, um_id, um_descrizione, hasUm);
        },


    }
}
    export default component;
    </script>

<style lang="scss">
.zone {
    overflow-x: auto;
    .table-zone {
        td {
            height: auto;
            padding: calc(2 * var(--ui-space));
            border-bottom: 2px solid #fff;
        }
        th {
            border: none;
        }
    }
    td {
        background: #fff !important;
        text-align: left;
    }
    td.table-zone-label {
        font-weight: bold;
        color: var(--col-grigio-scuro);
        // width: 4em;
        // box-sizing: content-box;
        // border-right: 10px solid var(--col-grigio);
    }
    .table-zone-z {
        td:nth-child(2),
        td:nth-child(3) {
            background-color: #fff!important;
        }
    }
    .buttons {
        display: flex;
        gap: 5px;
        padding: 7px 0;
    }
}
</style>
